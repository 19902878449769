
































import PartnerHome from '@/components/partnerHome/partnerHome';
export default PartnerHome;
