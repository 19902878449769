import programListStore from '@/store/modules/programList';
import { Vue, Component, Prop, Watch, Inject } from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';
import {fetchLMSUrl} from '@/services/dashboard/dashboardService';
import ProgramList from '@/components/partnerHome/components/programList/ProgramList.vue';
import accountListStore from '@/store/modules/accountsList';
import APP_UTILITIES from '@/utilities/commonFunctions';
import APP_CONST from '@/constants/AppConst';
import { AnalyticsInjectionKey, AnalyticsService, analyticsEventNames } from '@/analytics';


@Component({
  components:{
    'program-list': ProgramList
  }
})
export default class PartnerHome extends Vue{
    @Inject(AnalyticsInjectionKey)
    private readonly analyticsService!: AnalyticsService;

    private objScreenText: ScreenText = new ScreenText();
    public programsDetail:Array<Object> = [];
    public emptyView: boolean = false;
    public programId:number=0;
    public accountId:number=0;
    public styleObj:any = {};
    public hoverText:string = '';
    public proLearningId : number = 1;
    public showProffessionalLearning: boolean = false;


    /* istanbul ignore next */
    get lxEnabled() {
      return programListStore.appSetting;
    }
    @Watch('lxEnabled', {immediate: true, deep: true})
    cmsFeatureFlag(store: any){
      const highestRole = Number(APP_UTILITIES.getCookie('highest_role'));
      const isBxl = highestRole !== null && highestRole < APP_CONST.FOUR;
      if(isBxl){
        return true;
      }
      else {
        const {isLoading, lx} = store;
        if(isLoading){
          //Do Nothing
        }
        else{
          Vue.set(this, 'showProffessionalLearning', !lx);
        }
      }
    }



    @Prop()
    userDetails!: any;

    public getScreenText(key: string): string {
      return this.objScreenText.getScreenText(key);
    }

    /* istanbul ignore next */
    get programsList() {
      return programListStore.programAndSiteList;
    }

    public navigateProLerningUrl() {
      const newWindow: any = window.open();
      /* istanbul ignore else */
      if (this.proLearningId) {
        fetchLMSUrl(this.proLearningId).then((res) => {
          /* istanbul ignore else */
          if (res.status === 200) {
            /* istanbul ignore if */
            if (navigator.userAgent.indexOf('Safari') != -1) {
              newWindow.location = res.data;
            }
            else {
              window.open(res.data, '_blank');
            }
          }
        });
      }
    }
    showTooltip(val : string, $event: any){
      this.hoverText = val;
      const boundBox = $event && $event.target.getBoundingClientRect();
      const coordX = boundBox.left;
      const coordY = boundBox.top;
      this.styleObj = {
        top:(coordY + 50).toString() + 'px',
        left:(coordX + 70).toString() + 'px',
        width: 'fit-content;'
      };
    }

    hideTooltip(){
      this.hoverText = '';
      this.styleObj= {};
    }

    mounted(){
      this.analyticsService.track(analyticsEventNames.LANDING_PAGE_VIEWED);
      accountListStore.setAccountCourseStatuses();
    }


}
